.App {
  text-align: center;
}

.css-1032tb7-MuiFormLabel-root-MuiInputLabel-root{
  background-color: #fff;
}

.MuiDrawer-paper{
  background-color: #4A4A4A !important;
}

.MuiTabs-root {
  background-color: #4A4A4A !important;
}